// z-index layers – keep this list in ascending order of values
// so that we can easily see the stacking order at a glance
$z-indices: (
  content-underlay: -1,
  menu-modal: 0,
  featured-hero-navigation: 1,
  centre-selector-popup: 2,
  content-overlay: 2,
  parking-utility-box: 2,
  deck-button: 2,
  feedback-button: 2,
  social-deck-card-hover: 3,
  w-device-mask: 3,
  header: 4,
  menu-overlay: 5,
  modal: 5,
  search-overlay: 7,
);

@function layers($name) {
  @return map-get($z-indices, $name);
}

$animation-fast: 300;
$animation-slow: 800;
$animation-fast-ms: $animation-fast + 0ms;
$animation-slow-ms: $animation-slow + 0ms;
$transform-cubic-bezier: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);

/* stylelint-disable */
:export {
  animation-fast-ms: $animation-fast;
  animation-slow-ms: $animation-slow;
}
/* stylelint-enable */
