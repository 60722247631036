@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '~@scentregroup/ui/tools/layout.module.scss';
@import '../../assets/sass/mixins.module.scss';
@import '../../assets/sass/variables.module.scss';
@import './variables.module.scss';

.scrollContent {
  padding-left: $deck-padding-phone;
  padding-right: $deck-padding-phone;

  @include scroll-snap-type(x, mandatory);
  @include scroll-padding(0, $deck-padding-phone);

  @include fromBreakpoint(tablet) {
    padding-left: $deck-padding-desktop;
    padding-right: $deck-padding-desktop;

    @include scroll-padding(0, $deck-padding-desktop);
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    padding-left: 0;
    padding-right: 0;

    @include scroll-padding(0, 0);
  }
}

.backButton,
.forwardButton {
  background-color: color('White');
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  margin: auto 0;
  height: units(4);
  width: units(4);
  position: absolute;
  z-index: layers(deck-button);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.enabled {
  opacity: 0.999;
  pointer-events: auto;
}

.icon {
  width: units(1.5);
  height: units(1.5);
  display: block;
  color: color('Black');
}

.evolvedButton {
  height: units(3);
  width: units(3);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: color('Smoke');
    cursor: pointer;
  }

  .icon {
    width: units(1);
    height: units(1);
  }

  @include breakpoint(phablet) {
    display: none;
  }
}

.backButton {
  left: units(0.5);

  &.evolvedButton {
    left: units(-1.25);
  }

  @include fromBreakpoint(tablet) {
    left: units(1);
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    left: units(-2);
  }
}

.forwardButton {
  right: units(0.5);

  &.evolvedButton {
    right: units(-1.25);
  }

  @include fromBreakpoint(tablet) {
    right: units(1);
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    right: units(-2);
  }
}

.evolved {
  @function button-margin-top($outer-padding, $column-count) {
    $spacing: calc(
      2 * #{$outer-padding} + (#{$column-count} - 1) * #{$grid-gap-evolved}
    );

    @return calc((50% - #{$spacing} / 2) / #{$column-count});
  }

  .scrollContent {
    padding-left: $deck-padding-evolved-phone;
    padding-right: $deck-padding-evolved-phone;

    @include scroll-padding(0, $deck-padding-evolved-phone);

    @include fromBreakpoint(phablet) {
      padding-left: 0;
      padding-right: 0;

      @include scroll-padding(0, 0);
    }
  }

  .backButton,
  .forwardButton {
    top: 0;
    margin-top: button-margin-top(
      $deck-padding-evolved-phone,
      $grid-columns-phone
    );

    @include fromBreakpoint($grid-breakpoint-phone) {
      margin-top: button-margin-top(
        $deck-padding-evolved-phone,
        $grid-columns-phablet
      );
    }

    @include fromBreakpoint(phablet) {
      margin-top: button-margin-top(
        $deck-padding-evolved-tablet,
        $grid-columns-tablet
      );
    }

    @include fromBreakpoint(tablet) {
      margin-top: button-margin-top(
        $deck-padding-evolved-desktop,
        $grid-columns-desktop
      );
    }
  }
}
