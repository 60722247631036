@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';

.DeckCard {
  composes: bodyFont from '@scentregroup/ui/tools/typography.module.scss';
  text-align: center;
}

.DeckCard a {
  text-decoration: none;
  color: color('Black');
}

.cardBody,
.cardInner {
  background: color('White');
}

.cardBody {
  height: columns(3);
}

.cardInner {
  position: relative;
  width: calc(100% - #{units(2)});
  padding-top: units(0.5);
  margin-top: -#{units(2)};
  margin-left: units(1);
}

.link {
  color: inherit;
  text-decoration: none;
}

.image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.metaWrapper:not(:empty) {
  margin-bottom: units(1);
}

.meta {
  @include font-size(0);
  color: color('Grey Dark');
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & + & {
    padding-top: units(0.5);
  }
}

.description {
  @include font-size(1);
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: units(5);
}

.title {
  @include font-size(1);
  color: color('Brand');
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  padding: 0 units(0.5) units(0.5);
}

.evolved {
  .heading {
    margin-bottom: units(0.5);
  }

  .deckFiltersContainer {
    margin-bottom: units(0);
  }

  &.DeckCard {
    display: flex;
    text-align: left;

    @include breakpoint(phablet) {
      // taken from inline deck, ensures next item is peaking
      width: calc((100% - (8px + 24px + 24px)) / 1.2);
    }

    > *,
    a {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .cardBody,
  .cardInner {
    background: transparent;
  }

  .cardInner {
    margin: 0;
    padding: units(0.5) 0;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .metaWrapper:not(:empty) {
    margin-bottom: 0;
    margin-top: units(0.25);
  }

  .cardBody {
    height: auto;
    flex-grow: 1;
  }

  .description {
    @include font-size(0);
    color: color('Black');
    line-height: units(1.25);
    font-weight: bold;
    max-height: none;
  }

  .meta {
    line-height: units(1);

    &:not(:first-child) {
      padding-top: units(0.25);
    }
  }

  &.padded {
    .cardBody,
    .cardInner {
      background: color('White');
    }

    .cardInner {
      padding: units(1);
    }
  }
}
