@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';


@import '~@scentregroup/ui/tools/color.module.scss';

.ResponsiveImage {
  width: 100%;
  display: block;
}

.placeholder {
  background-color: color('Grey');
}

// Keep these in sync with shared/helpers/cloudinary-url.js
$ratios: (
  square: math.percentage(1),
  standard: math.percentage(math.div(2, 3)),
  poster: math.percentage(3 * 0.5),
  feature: math.percentage(math.div(9, 21)),
);

// https://css-tricks.com/aspect-ratio-boxes/#article-header-id-6
.ratio::before {
  content: '';
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
}

.ratio::after {
  content: '';
  display: table;
  clear: both;
}

@each $name, $percentage in $ratios {
  .desktopRatio_#{$name} {
    composes: ratio;

    &::before {
      padding-bottom: $percentage;
    }
  }
}

@media (max-width: 640px) {
  @each $name, $percentage in $ratios {
    .mobileRatio_#{$name} {
      &::before {
        padding-bottom: $percentage;
      }
    }
  }
}
