@import '~@scentregroup/ui/tools/layout.module.scss';

// ===== CONFIG VALUES =====

// Side margin/padding for the whole component — matches other containers.
$deck-padding-phone: units(1.5);
$deck-padding-desktop: units(3);
$deck-padding-evolved-phone: units(2);
// the units(0) instead of 0 below are needed for some calculations :(
$deck-padding-grid-breakpoint-desktop: units(0);
$deck-padding-evolved-tablet: units(0);
$deck-padding-evolved-desktop: $deck-padding-evolved-tablet;

// Size of the gap between each grid item (vertical and horizontal).
$grid-gap: units(0.5);
$grid-gap-evolved: units(1);

// How many columns to show per page at each breakpoint.
$grid-columns-phone: 1;
$grid-columns-phablet: 2;
$grid-columns-tablet: 3;
$grid-columns-desktop: 4;

// Use a slightly larger breakpoint before switching to 2 columns, to avoid
// the grid items from becoming too small.
$grid-breakpoint-phone: columns(8);

// Use a custom "desktop" breakpoint, based on the max width of a container.
$grid-breakpoint-desktop: $container-large-width;

// ===== DERIVED VALUES & HELPERS =====

$grid-max-width-phone: $grid-breakpoint-phone - ($deck-padding-phone * 2);
$grid-max-width-phablet: $breakpoint-phablet - ($deck-padding-phone * 2);
$grid-max-width-tablet: $breakpoint-tablet - ($deck-padding-phone * 2);
$grid-max-width-desktop: $grid-breakpoint-desktop - ($deck-padding-desktop * 2);

@function grid-item-width($columns) {
  $total-gaps: $grid-gap * ($columns - 1);

  @return calc((100% - #{$total-gaps}) / #{$columns});
}

@function grid-item-width-evolved($columns) {
  $total-gaps: $grid-gap-evolved * ($columns - 1);

  @return calc((100% - #{$total-gaps}) / #{$columns});
}
