@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '../../assets/sass/variables.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';

$colorDarkGrey: color('Grey Dark');
$colorWhite: color('White');

.CosmeticScrollbar {
  width: 100%;
  border-bottom: 2px solid rgba($colorDarkGrey, 0.5);
}

.lightTheme {
  border-bottom-color: rgba($colorWhite, 0.5);
}

.bar {
  position: relative;
  top: 2px;
  height: 4px;
  background: $colorDarkGrey;
  transition: $transform-cubic-bezier;

  @supports (-webkit-overflow-scrolling: touch) or (scroll-behavior: smooth) {
    transition: none;
  }
}

.lightThemeBar {
  background: $colorWhite;
}
