@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '../deck-scroll-wrapper/variables.module.scss';

// Needed due to a WebKit bug: https://bugs.webkit.org/show_bug.cgi?id=137043
%hideScrollbarWrapper {
  @supports (-webkit-overflow-scrolling: touch) {
    overflow-y: hidden;
  }
}

%hideScrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 20px;
    margin-bottom: -20px;
  }
}

.HorizontalScrollWrapper {
  position: relative;
}

.scrollContentWrapper {
  @extend %hideScrollbarWrapper;
}

.scrollContent {
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @extend %hideScrollbar;
}

.cosmeticWrapper {
  display: flex;
  justify-content: center;
  padding: units(1) 0;
}

.cosmeticInner {
  width: 160px;
}

.evolved {
  &.HorizontalScrollWrapper {
    margin: units(1) -#{$deck-padding-evolved-phone};

    @include fromBreakpoint(phablet) {
      margin: units(1) -#{$deck-padding-evolved-tablet};
    }
  }

  &.cosmeticWrapper {
    padding: 0 0 units(1);
  }
}
