@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '../../assets/sass/mixins.module.scss';
@import '../deck-scroll-wrapper/variables.module.scss';

.deckContainer {
  @include fromBreakpoint($grid-breakpoint-desktop) {
    padding: 0 $deck-padding-desktop;
  }
}

.heading {
  padding-bottom: units(1);
}

.deckCardList {
  display: flex;

  // Include a dummy flex item to fake padding-right within the container:
  // https://itnext.io/horizontal-overflow-with-flexbox-css-64f530495303
  &::after {
    content: '';
    flex: 0 0 auto;
    width: $deck-padding-phone;

    @include fromBreakpoint(tablet) {
      width: $deck-padding-desktop;
    }

    @include fromBreakpoint($grid-breakpoint-desktop) {
      display: none;
    }
  }
}

.deckCard {
  flex-shrink: 0;
  width: grid-item-width($grid-columns-phone);

  @include scroll-snap-align(start);

  @include fromBreakpoint($grid-breakpoint-phone) {
    width: grid-item-width($grid-columns-phablet);
  }

  @include fromBreakpoint(phablet) {
    width: grid-item-width($grid-columns-tablet);
  }

  @include fromBreakpoint(tablet) {
    width: grid-item-width($grid-columns-desktop);
  }

  & + & {
    margin-left: $grid-gap;
  }
}

.deckFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 $deck-padding-phone;

  @include fromBreakpoint(tablet) {
    margin: 0 $deck-padding-desktop;
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    margin: 0;
  }
}

.whatsOnNav {
  align-self: center;
  display: flex;
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: units(1);
  mask: linear-gradient(to right, black 0, black 85%, transparent 100%);

  @include fromBreakpoint(phablet) {
    mask: none;
    flex-grow: 0;
  }
}

.whatsOnNav::after {
  content: '';
  flex: 0 0 60px;

  @include fromBreakpoint(phablet) {
    content: none;
    flex: unset;
  }
}

.whatsOnNavItem {
  margin: 0 units(0.5) 0 0;
}

.whatsOnNavItem:last-of-type {
  margin-right: 0;
}

.callToAction {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: units(1) 0;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  @include fromBreakpoint(tablet) {
    max-width: 60%;
  }
}

.warningNotification {
  padding: 10px 0;
  margin: 0 $deck-padding-phone;

  @include fromBreakpoint(tablet) {
    margin: 0 $deck-padding-desktop;
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    margin: 0;
  }
}

.evolved {
  .heading {
    text-align: center;
    padding-bottom: units(0.5);
  }

  &.deckContainer {
    padding: 0;
  }

  .filterLinks {
    color: color('Grey Dark');
    font-size: units(1);
    text-decoration: underline;
    white-space: nowrap;
    margin: 0 units(0.5) 0 0;
  }

  .deckCardList {
    &::after {
      @include fromBreakpoint(phablet) {
        display: none;
      }
    }
  }

  .deckFiltersContainer {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .whatsOnNav {
    padding-bottom: 0;
    gap: units(1.5);

    @include breakpoint(phone) {
      mask: linear-gradient(to right, black 0, black 95%, transparent 100%);
    }

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none; /* Firefox */
    }

    @include breakpoint(phablet) {
      gap: units(1);
    }
  }

  .warningNotification {
    margin: 0;
  }

  .deckCard {
    width: grid-item-width-evolved($grid-columns-phone);

    @include scroll-snap-align(start);

    @include fromBreakpoint($grid-breakpoint-phone) {
      width: grid-item-width-evolved($grid-columns-phablet);
    }

    @include fromBreakpoint(phablet) {
      width: grid-item-width-evolved($grid-columns-tablet);
    }

    @include fromBreakpoint(tablet) {
      width: grid-item-width-evolved($grid-columns-desktop);
    }
  }

  .deckCard + .deckCard {
    margin-left: $grid-gap-evolved;
  }

  .contentWrapper {
    margin-bottom: 0;
  }

  .callToActionLink {
    display: block;
    font-size: units(1);
    text-align: center;
    text-decoration: underline;
  }
}
