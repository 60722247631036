// Dirg breakpoint
// ===============

$breakpoint-desktop-large: columns(28);
$breakpoint-desktop-small: columns(21);
$breakpoint-tablet: columns(14.5);
$breakpoint-phablet: columns(11);
$breakpoint-phone: columns(7);

@mixin breakpoint($point) {
  @if $point == 'desktop-large' {
    @media all and (max-width: #{$breakpoint-desktop-large - 1}) {
      @content;
    }
  } @else if $point == 'desktop-small' {
    @media all and (max-width: #{$breakpoint-desktop-small - 1}) {
      @content;
    }
  } @else if $point == 'tablet' {
    @media all and (max-width: #{$breakpoint-tablet - 1}) {
      @content;
    }
  } @else if $point == 'phablet' {
    @media all and (max-width: #{$breakpoint-phablet - 1}) {
      @content;
    }
  } @else if $point == 'phone' {
    @media all and (max-width: #{$breakpoint-phone - 1}) {
      @content;
    }
  } @else {
    @media all and (max-width: $point) {
      @content;
    }
  }
}

@mixin fromBreakpoint($point) {
  @if $point == 'desktop-large' {
    @media all and (min-width: $breakpoint-desktop-large) {
      @content;
    }
  } @else if $point == 'desktop-small' {
    @media all and (min-width: $breakpoint-desktop-small) {
      @content;
    }
  } @else if $point == 'tablet' {
    @media all and (min-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $point == 'phablet' {
    @media all and (min-width: $breakpoint-phablet) {
      @content;
    }
  } @else if $point == 'phone' {
    @media all and (min-width: $breakpoint-phone) {
      @content;
    }
  } @else {
    @media all and (min-width: $point) {
      @content;
    }
  }
}
