

@import '~@scentregroup/ui/tools/if-theme.module.scss';

// Dirg
// ====
//
// See https://github.com/juliocesar/dirg/

$scale: (
  font-size: 16px,
  unit: 16px,
  factor: 1,
);

$fixed-sizes: (
  (14px, 16px),
  (16px, 24px),
  (18px, 28px),
  (20px, 28px),
  (24px, 32px),
  (28px, 32px),
  (32px, 40px),
  (40px, 48px),
  (48px, 56px),
  (52px, 64px),
  (72px, 80px)
);

$fixed-size-mappings: (
  -3: 1,
  -2: 1,
  -1: 1,
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
  7: 8,
  8: 9,
  9: 10,
  10: 11
);

@function fetchFixedSizeInTuple($index, $n) {
  $mappedX: map-get($fixed-size-mappings, $index);
  @return nth(nth($fixed-sizes, $mappedX), $n);
}

@function fetchFixedFontSize($index) {
  @return fetchFixedSizeInTuple($index, 1);
}

@function fetchFixedLineHeight($index) {
  @return fetchFixedSizeInTuple($index, 2);
}

@function usesLinearScale() {
  @return false;
}

// A power calculation function. See:
// http://sassmeister.com/gist/10620fefd1ed75189f1b
@function pow($x, $y) {
  $ret: 1;

  @if $y > 0 {
    @for $i from 1 through $y {
      $ret: $ret * $x;
    }
  } @else {
    @for $i from $y to 0 {
      $ret: math.div($ret, $x);
    }
  }

  @return $ret;
}

// Returns a value to be used in `font-size`.
@function font-scale($x) {
  @if usesLinearScale() {
    $factor: map-get($scale, 'factor');
    $font-size: map-get($scale, 'font-size');

    @return round($font-size * pow($factor, $x));
  } @else {
    @return fetchFixedFontSize($x);
  }
}

// Returns the diff between two values in a growth/shrink factor.
@function factor-diff($args...) {
  $arguments: keywords($args);

  @return math.div(map-get($arguments, to), map-get($arguments, from));
}

// Returns a grid unit of space, calculated from the scale above.
@function units($x) {
  @return round(map-get($scale, 'unit') * $x);
}

// Shortcut for adding multiple units and institute columns thinking.
@function columns($x) {
  @return units(4) * $x;
}

// A helper for setting an appropriate font size and line-height for it.
@mixin font-size($units) {
  font-size: font-scale($units);
  line-height: line-height($units);
}

// A helper for determining the relevant line-height for a text size.
@function line-height($units) {
  $unit: map-get($scale, 'unit');
  $lh: $unit;

  @if usesLinearScale() {
    @while $lh < font-scale($units) {
      $lh: $lh + $unit;
    }
  } @else {
    $lh: fetchFixedLineHeight($units);
  }

  @return $lh;
}
