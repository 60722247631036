@mixin ie-11-only {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin supports-angles {
  /*
   * Only include angle masks when the browser supports CSS masking.
   * `not (-ms-ime-align: auto)` is to exclude Edge, which has experimental
   * support for masks (as of Edge 17), but doesn't support all use cases.
   * Turn on Edge support once there's a version that supports masking properly.
   */
  /* stylelint-disable */
  @supports ((mask-repeat: no-repeat) or (-webkit-mask-repeat: no-repeat)) and
    (not (-ms-ime-align: auto)) {
    @content;
  }
  /* stylelint-enable */
}

/**
 * CSS Scroll Snap mixins
 *
 * Browser support is very mixed across 2 specifications (1 deprecated):
 * https://caniuse.com/#feat=css-snappoints
 * - IE & Edge theoretically support the old spec, but the support is so limited
 *   that they're not worth bothering with.
 * - Firefox supports the old spec, but the implementation is very buggy.
 *   We've actively decided to exclude Firefox support to avoid the bugs.
 * - Safari 9-10 support the old spec, with -webkit- prefix.
 * - Safari 11+ and Chrome support the new spec.
 */

@mixin scroll-snap-type($direction, $type) {
  // Old spec (CSS Scroll Snap Points)
  -webkit-scroll-snap-type: $type;
  // New spec (CSS Scroll Snap)
  scroll-snap-type: $direction $type;
}

@mixin scroll-padding($vertical, $horizontal) {
  // Old spec (CSS Scroll Snap Points)
  -webkit-scroll-snap-destination: $horizontal $vertical;
  // New spec (CSS Scroll Snap)
  scroll-padding: $vertical $horizontal;
}

@mixin scroll-snap-align($alignment) {
  $coord: 0;

  @if $alignment == 'center' {
    $coord: 50%;
  } @else if $alignment == 'end' {
    $coord: 100%;
  }

  // Old spec (CSS Scroll Snap Points)
  -webkit-scroll-snap-coordinate: $coord $coord;
  // New spec (CSS Scroll Snap)
  scroll-snap-align: $alignment;
}
