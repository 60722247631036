@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
// Typography settings
// ===================

@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/if-theme.module.scss';
@import '~@scentregroup/ui/tools/fonts.module.scss';

.bodyFont {
  @include bodyFont;
}

.body {
  @include bodyFont;
}

.heading {
  @include headingFont;
}

.headingExtraLarge {
  composes: heading;
  @include font-size(9);

  @include breakpoint(phablet) {
    @include font-size(7);
  }

  @include if-theme('scentregroup') {
    @include font-size(4);
  }
}

.headingLarge {
  composes: heading;
  @include font-size(7);

  @include breakpoint(phablet) {
    @include font-size(5);
  }

  @include if-theme('scentregroup') {
    @include font-size(4);
  }
}

.headingMedium {
  composes: heading;
  @include font-size(6);

  @include breakpoint(phablet) {
    @include font-size(4);
  }

  @include if-theme('scentregroup') {
    @include font-size(3);
  }
}

.headingSmall {
  composes: heading;
  @include font-size(4);

  @include breakpoint(phablet) {
    @include font-size(3);
  }

  @include if-theme('scentregroup') {
    @include font-size(2);
  }
}

.headingExtraSmall {
  composes: heading;
  @include font-size(2);

  @include breakpoint(phablet) {
    @include font-size(1);
  }

  @include if-theme('scentregroup') {
    @include font-size(2);
  }
}

.paragraphLarge {
  composes: bodyFont;
  @include font-size(2);
}

.paragraphMedium {
  composes: bodyFont;
  @include font-size(1);
}

.paragraphSmall {
  composes: bodyFont;
  @include font-size(0);
}
